import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../../common/Layout/Layout';
import Seo from '../../common/Seo/Seo';
import CenteredPageContainer from '../../common/CenteredPageContainer/CenteredPageContainer';

import backgroundImage from './images/not-found-background.svg';

const NotFound = () => (
  <Layout>
    <Seo title="404" />

    <CenteredPageContainer>
      <S.BackgroundImage
        src={backgroundImage}
        alt="Representação de alguns elementos utilizados na construção de um website"
      />
      <S.InfoContainer>
        <S.Title>ops, isto não era para ter acontecido…</S.Title>

        <S.Description>
          Desculpe-nos, o endereço digitado não existe ou está indisponível no
          momento. Quem sabe na nossa página inicial você não encontra o que
          procura?
        </S.Description>

        <S.StyledLink to="/">voltar para a home</S.StyledLink>
      </S.InfoContainer>
    </CenteredPageContainer>
  </Layout>
);

const S = {
  BackgroundImage: styled.img`
    margin-left: -64px;
    width: 190px;
    height: 184.3px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-left: 0;
      margin-right: 32px;
      width: 337px;
      height: 261.9px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-right: 0;
    }
  `,

  InfoContainer: styled.div`
    margin-top: 38px;
    max-width: 296px;
    color: var(--color-secondary, #37474f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 0;
      max-width: 432px;
    }
  `,

  Title: styled.h1`
    max-width: 261px;
    font-size: 24px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 29.9px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 432px;
      font-size: 40px;
      line-height: 44px;
    }
  `,

  Description: styled.p`
    margin-top: 24px;
    max-width: 295px;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-text, #7f7f7f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 413px;
    }
  `,

  StyledLink: styled(Link)`
    margin-top: 24px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 12px;
    letter-spacing: 0.48px;
    color: var(--color-text-highlight, #ffffff);
    text-transform: uppercase;
    background-color: var(--color-text-primary, #3f51b5);
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 32px;
      width: 324px;
    }

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }
  `,
};

export default NotFound;
