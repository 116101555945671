import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CenteredPageContainer = ({ children }) => (
  <S.Container>{children}</S.Container>
);

const verticalMargin = '32px';

const S = {
  Container: styled.div`
    margin: ${verticalMargin} auto;
    max-width: var(--grid-width, 1100px);
    min-height: calc(
      100vh - var(--navbar-height, 64px) - var(--footer-mobile-height, 192px) -
        (${verticalMargin} * 2)
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      min-height: calc(
        100vh - var(--navbar-height, 64px) - var(--footer-desktop-height, 74px) -
          (${verticalMargin} * 2)
      );
      flex-direction: row;
      justify-content: space-evenly;
    }
  `,
};

CenteredPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CenteredPageContainer;
